﻿/*
--------------------------------------------------------------------------------
This module is controller of popup videos
--------------------------------------------------------------------------------
*/
(function() {
    "use strict";
    var self        = null,
        settings    = null;
    //Settings of module
    settings = {
        //Selectors
        selectors   : {
            LIST_VIDEO_IDS          : "#youtube-player-container",
            RECORD_IN_LIST_VIDEO_IDS: "div[id=\"[video_id]\"]",
            POPUP_CONTAINER         : "#video-popup-container",
            BUTTONS_OPEN_VIDEO      : "a.js-ytplayer",
            BUTTONS_OPEN_VIDEO_POPUP: "a.js-ytplayer[data-openpopup=\"true\"]",
            VIDEO_IFRAME            : "#youtube-player-container iframe",
            //This only part of selector. It's used to prevent double initialization of some node
            NOT_INITED: ":not([data-video-inited])",
            CONTROL_BAR: ".ytp-controls-bar",
            popup                   : {
                CONTAINER   : ".k-window",
                CONTENT     : ".k-window-content"
            }
        },
        //Attributes
        attrs       : {
            VIDEO_ID    : "data-videoid",
            OPENPOPUP   : "data-openpopup",
            INITED      : "data-video-inited"
        },
        //Video settings
        video       : {
            RATIO   : 720 / 1280,
            WIDTH   : 1280,
            HEIGHT  : 720,
        },
        //Popup settings
        popup       : {
            //Default value for buttons with video. Value [true] -> open video in popup. [false] -> do not place video in popup
            OPEN    : true
        },
        //Everything about layout
        html        : {
            RECORD_IN_LIST_VIDEO_IDS: "<div id=\"[video_id]\"></div>"
        },
        //All other settings
        other       : {
            DEFAULT_VIDEO_CONTAINER_ID  : "youtube-player",
            AUTO_PLAY_HASH_MARK         : "play-video",
            DATA_STORAGE                : "ori.popup.video.storage"
        },
        //Defaults parameters of popup box
        POPUP_DEFAULT_SETTINGS: {
            title       : false,
            width       : 854,
            height      : 480,
        }
    };
    /**
     * Add module as child of module POPUP. It means module [ori.popup] should be loaded first
     */
    ori.popup.addModule("popupVideo", {
        //Block for popup modal window
        popup: {
            //Init
            init : function (popup_box_option) {
                //Get options object
                var options = self.popup.getParameters(popup_box_option);
                //Correct attribute "open as popup" (settings.attr.OPENPOPUP)
                self.popup.checkAttributes();
                //Attach popup to buttons
                $(settings.selectors.BUTTONS_OPEN_VIDEO_POPUP).on(self.eventString("click"), function (event) {
                    var popup = $(settings.selectors.POPUP_CONTAINER);
                    event.preventDefault();

                    if (popup.length > 0) {
                        popup.kendoWindow(options);
                        var kendoPopupWindow = popup.data("kendoWindow");
                        kendoPopupWindow.open();
                    }
                });
                //Attach events
                self.popup.events.attach();
            },
            //Build object of parameters for popup
            getParameters   : function (popup_box_option) {
                /**
                 * We have default values [settings.POPUP_DEFAULT_SETTINGS] and [popup_box_option] (from view for example)
                 * Here we merge it. Primary is [popup_box_option]. If in [popup_box_option] isn't some necessory value
                 * we take it from [settings.POPUP_DEFAULT_SETTINGS]
                 */
                var options             = {},
                    popup_box_option    = typeof popup_box_option === "object" ? popup_box_option : {};
                //Check defaults
                for (var key in settings.POPUP_DEFAULT_SETTINGS) {
                    if (!settings.POPUP_DEFAULT_SETTINGS.hasOwnProperty(key)){
                        continue;
                    }

                    options[key] = typeof popup_box_option[key] !== "undefined" ? popup_box_option[key] : settings.POPUP_DEFAULT_SETTINGS[key];
                    popup_box_option[key] = null;
                    delete popup_box_option[key];
                }
                //Add all missed from [popup_box_option]
                for (var key in popup_box_option) {
                    if (!popup_box_option.hasOwnProperty(key)){
                        continue;
                    }

                    options[key] = popup_box_option[key];
                }
                //Add handles (events, which can be imported)
                var imported = self.popup.events.imported;

                for (var key in imported) {
                    if (!imported.hasOwnProperty(key)) {
                        continue;
                    }

                    options[key] = imported[key];
                }

                options.width  = typeof popup_box_option.innerWidth    !== "undefined" ? options.innerWidth    : settings.video.WIDTH;
                options.height = typeof popup_box_option.innerHeight   !== "undefined" ? options.innerHeight   : settings.video.HEIGHT;
                return options;
            },
            //Check attribute "open as popup" (settings.attr.OPENPOPUP) and set it as default if it wasn't set
            checkAttributes : function() {
                //Get all video's buttons (<a>, which has information about video (links, ids and etc.)
                var placeholders = $(settings.selectors.BUTTONS_OPEN_VIDEO);
                Array.prototype.forEach.call(placeholders, function (placeholder) {
                    var openAsPopup = $(placeholder).attr(settings.attrs.OPENPOPUP);
                    if (openAsPopup !== null && openAsPopup !== "") {
                        //Good, button has attribute, but we have to check value of it
                        if (["true", "false"].indexOf(openAsPopup) === -1) {
                            $(placeholder).attr(settings.attrs.OPENPOPUP, settings.popup.OPEN.toString());
                        }
                    } else {
                        //If button hasn't attribute "open as popup", add it with default value (settings.attr.OPENPOPUP)
                        $(placeholder).attr(settings.attrs.OPENPOPUP, settings.popup.OPEN.toString());
                    }

                });
            },
            //Events of popup
            events          : {
                //Init events
                attach      : (function() {
                    //Attach only events, which should be attached.
                    var attached = false;
                    return function() {
                        if (attached === false) {
                            attached = true;
                            if (!ori.device) {
                                self.error("Couldn't find device module, layout updates may not work properly.");
                                return;
                            } else {
                                var resizeHandler = function () {
                                    self.popup.events.onResize(true);
                                };
                                ori.device.bindResize(resizeHandler, self.ns);
                                ori.device.bindMedia(resizeHandler, self.ns);
                            };
                        }
                    };
                }()),
                //Collection of events, which will be imported to popup controller
                imported    : {
                    //Event: Popup is opened, but content is not loaded yet
                    open      : function () {
                        //Add spinner during video is loading
                        if (ori.spinner) {
                            ori.spinner.applyOverlayTo(settings.selectors.popup.CONTAINER);
                        }
                    },
                    //Event: Popup is opened and content is loaded
                    activate  : function () {
                        //Remove spinner after video is load
                        if (ori.spinner) {
                            ori.spinner.removeOverlayFrom(settings.selectors.popup.CONTAINER);
                        }
                        self.popup.events.onResize(true);
                    },
                    //Event: Before popup will be closed
                    close   : function () {
                        var iFrame = $(settings.selectors.VIDEO_IFRAME),
                            id = null;
                        if (iFrame.length === 1) {
                            id = $(iFrame).attr("id");
                            if (id !== null && id !== "") {
                                //Remove iframe with video
                                $(iFrame).remove();
                                //Restore video record
                                $(settings.selectors.LIST_VIDEO_IDS).append(settings.html.RECORD_IN_LIST_VIDEO_IDS.replace("[video_id]", id));
                                $(settings.selectors.CONTROL_BAR).remove();
                                ori.ytplayer.youtube.clearYoutubeInterval();
                            }
                        }
                    }
                },
                //Event: resize of window
                onResize    : function (first) {
                    var iFrame  = $(settings.selectors.VIDEO_IFRAME),
                        box     = iFrame.closest($(settings.selectors.popup.CONTENT)),
                        width   = null,
                        height  = null,
                        first   = typeof first === "boolean" ? first : false;
                    if (iFrame.length === 1) {
                        /*
                        The logic is next: we get once size of video and for all next situation we are using
                        such size of video. Such way is better at least for mobile devices and for screens,
                        where can be big difference berween width and hight, because for video is basic metric is
                        width, but height is calculeted according ratio.
                        */
                        if (first !== false) {
                            //Get real size
                            width   = $(box).width();
                            height  = Math.ceil(width * settings.video.RATIO);
                            //Save size to storage
                            iFrame[0][settings.other.DATA_STORAGE] = {
                                width   : width,
                                height  : height,
                            };
                        } else {
                            //Restore data from storage
                            if (iFrame[0][settings.other.DATA_STORAGE]) {
                                width   = iFrame[0][settings.other.DATA_STORAGE].width;
                                height  = iFrame[0][settings.other.DATA_STORAGE].height;
                            }
                        }
                        //Change size of popup
                        var popupHeight = height + ($(box).outerHeight(true) - $(box).height());
                        var kendoPopupWindow = box.data("kendoWindow");
                        kendoPopupWindow.setOptions({
                            width: (width > settings.video.WIDTH) ? width : settings.video.WIDTH,
                            height: popupHeight
                        });
                        //Change size of iframe
                        $(iFrame).width(width);
                        $(iFrame).height(height);
                        iFrame[0].width     = width;
                        iFrame[0].height    = height;
                        //Change size of iframe container
                        $(iFrame[0].parentNode).width(width);
                        $(iFrame[0].parentNode).height(height);
                    }
                }
            },
        },
        //Block for work with placeholders. Placeholder - is a node with video ID, where this video should be placed
        placeholders: {
            validate: function () {
                //Get all video's buttons (<a>, which has information about video (links, ids and etc.)
                var placeholders = $(settings.selectors.BUTTONS_OPEN_VIDEO + settings.selectors.NOT_INITED);
                /**
                 * Check each button. Logic:
                 * On page should be container with list of all videos:
                   <div class="hidden">
                        <div id="video-popup-container">
                            <div id="youtube-player-container">
                                ===>This is list of videos with IDs<====
                                <div id="ncWhD0v7LVY"></div>
                                <div id="NBHJuJ_TeWc"></div>
                                ===>This is list of videos with IDs<====
                            </div>
                        </div>
                    </div>
                 * Each video in list should have some button (<a>) with same ID and information about video
                 * So, we check each button, and if we cannot find some video in list, we add it into list
                 */
                Array.prototype.forEach.call(placeholders, function (placeholder) {
                    var id      = $(placeholder).attr(settings.attrs.VIDEO_ID),
                        record  = null,
                        openAsPopup = $(placeholder).attr(settings.attrs.OPENPOPUP);

                    if (id !== null && id !== "" && openAsPopup !== "false") {
                        record = $(settings.selectors.RECORD_IN_LIST_VIDEO_IDS.replace("[video_id]", id));
                        if (record.length === 0) {
                            //If record for some video is missed - add it
                            $(settings.selectors.LIST_VIDEO_IDS).append(settings.html.RECORD_IN_LIST_VIDEO_IDS.replace("[video_id]", id));
                        }
                    } else {
                        //Something wrong, if button has not ID of video
                        self.info("[ori.video.popup]:: Button with video information has not ID of video.");
                    }
                    $(record).attr(settings.attrs.INITED, "true");
                });
            }
        },
        autoplay    : {
            //Check autoplay flag in hash of browser and start all videos if such flag is found.
            init    : function() {
                if (location.hash.search(settings.other.AUTO_PLAY_HASH_MARK) > -1) {
                    $(settings.selectors.BUTTONS_OPEN_VIDEO).click();
                    location.hash = location.hash.replace(settings.other.AUTO_PLAY_HASH_MARK, "");
                }
            }
        },
        container   : {
            //Correct container size
            init    : function () {
                var container   = $(settings.selectors.LIST_VIDEO_IDS + settings.selectors.NOT_INITED),
                    popup       = $(settings.selectors.POPUP_CONTAINER + settings.selectors.NOT_INITED);
                if (container.length > 0) {
                    //Set size of container
                    $(container).css({
                        'height': settings.video.HEIGHT,
                        'width': settings.video.WIDTH
                    });
                    $(popup).append($(container));
                    $(container).attr(settings.attrs.INITED, "true");
                }
            }
        },
        // initialize
        init        : function (popup_box_option) {
            //Validate video list
            self.placeholders.validate();
            //Init popup
            self.popup.init(popup_box_option);
            //Prepare container
            self.container.init();
            //Init player
            ori.ytplayer.init();
            //Init auto play
            //self.autoplay.init();
        },
    });
    //Setup self
    self = ori.popup.popupVideo;
}());